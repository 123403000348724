import {Injectable, OnDestroy} from "@angular/core"
import {LRUCache} from "lru-cache"
import {BehaviorSubject, from, Observable, of, ReplaySubject, Subject, Subscription, zip} from "rxjs"
import {catchError, concatMap, filter, map, mergeMap, reduce, tap, toArray} from "rxjs/operators"
import * as shiro from "shiro-trie"
import {App} from "../../interfaces/app"
import {Customer, CustomerNames} from "../../interfaces/customer"
import {License} from "../../interfaces/license"
import {UserRoles} from "../../interfaces/role"
import {ScopeActions} from "../../store/scope.actions"
import {AppService} from "../app/app.service"
import {CustomerService} from "../customer/customer.service"
import {KeycloakService} from "../keycloak/keycloak.service"
import {LicenseService} from "../license/license.service"
import {PluginService} from "../plugin/plugin.service"
import {UserService} from "../user/user.service"

export enum ScopeEventType {
  SCOPE_CHANGED
}

export interface ScopeEvent {
  type: ScopeEventType
}

@Injectable({
  providedIn: "root"
})
export class ScopeService implements OnDestroy {

  readonly RECENT_CUSTOMER_IDS_LOCAL_STORAGE_KEY = "cmn-recent-customer-ids"

  private scope = ""
  private apps: App[] = []
  private appNames: string[] = []
  private pluginIds: string[] = []
  private pluginNames: string[] = []
  private roleNames: LRUCache<string, string[]> = new LRUCache({max: 500, ttl: 240000}) // 240s
  private permissions: LRUCache<string, string[]> = new LRUCache({max: 500, ttl: 240000}) // 240s
  private permAndRoles$: LRUCache<string, Subject<[string[], string[]]>> = new LRUCache({max: 10, ttl: 500}) // 500ms
  private scope$ = new BehaviorSubject<string|undefined>(undefined)
  private scopeCustomer$ = new BehaviorSubject<Customer|undefined>(undefined)
  private apps$ = new BehaviorSubject<App[]>(this.apps)
  private appNames$ = new BehaviorSubject<string[]>(this.appNames)
  private pluginIds$ = new BehaviorSubject<string[]>(this.pluginIds)
  private pluginNames$ = new BehaviorSubject<string[]>(this.pluginNames)
  private availablePluginNames$ = new BehaviorSubject<string[]>([])
  private roleNames$ = new BehaviorSubject<Map<string, string[]>>(new Map())
  private permissions$ = new BehaviorSubject<Map<string, string[]>>(new Map())
  private events$ = new Subject<ScopeEvent>()

  subscriptions: Subscription = new Subscription()

  constructor(
    private customerService: CustomerService,
    private appService: AppService,
    private pluginService: PluginService,
    private keycloakService: KeycloakService,
    private userService: UserService,
    private licenseService: LicenseService,
    private scopeActions: ScopeActions
  ) {
  }

  initializeScopeService() {
    this.subscriptions.add(this.keycloakService.getRefreshedToken().subscribe(() => {
      if (this.scope == "") {
        this.initializeScopeCustomers()
      }
    }))
    this.initializeScopeCustomers()
  }

  initializeScopeCustomers() {
    if (this.keycloakService.getApiUserId()) {
      this.subscriptions.add(
        of(window.localStorage.getItem("cid")).pipe(
          mergeMap(scopeFromLocalStorage => {
            if (scopeFromLocalStorage) {
              return this.customerService.getCustomer(scopeFromLocalStorage, scopeFromLocalStorage)
            } else {
              return this.customerService.getCustomer(this.keycloakService.getCustomerIds()[0], this.keycloakService.getCustomerIds()[0])
            }
          }),
          catchError(_ => this.customerService.getCustomer(this.keycloakService.getCustomerIds()[0], this.keycloakService.getCustomerIds()[0]))
        ).subscribe(customer => this.updateScope(customer))
      )
    }
  }

  updateScope(customer: Customer): void {
    this.scopeCustomer$.next(customer)
    this.scope = customer?.id
    window.localStorage.setItem("cid", this.scope)
    this.addRecentCustomer(this.scope)
    this.scopeActions.updateScope(this.scope)
    this.scope$.next(this.scope)
    this.events$.next({
      type: ScopeEventType.SCOPE_CHANGED
    })
    this.refreshApps()
    this.refreshUser()
  }

  private addRecentCustomer(scope: string): void {
    const customerIds = [scope, ...this.getRecentCustomerIds().filter(customerId => customerId !== scope)].slice(0, 5)
    this.updateRecentCustomers(customerIds)
  }

  private removeRecentCustomer(scope: string): void {
    const customerIds = this.getRecentCustomerIds().filter(customerId => customerId !== scope)
    this.updateRecentCustomers(customerIds)
  }

  private updateRecentCustomers(customerIds: string[]): void {
    window.localStorage.setItem(this.RECENT_CUSTOMER_IDS_LOCAL_STORAGE_KEY, JSON.stringify(customerIds))
  }

  /**
   * Returns customer id that represents current scope.
   */
  getScope(): string {
    return this.scope
  }

  refreshApps() {
    this.subscriptions.add(
      this.getAppsInternal().pipe(
        tap(apps => {
          this.apps = apps as App[]
          this.apps$.next(this.apps)
          this.appNames = apps.map(app => app?.name) as string[]
          this.appNames$.next(this.appNames)
        }),
        mergeMap(apps => from(apps)),
        mergeMap(app => zip(
          this.pluginService.getPluginsOfApp(app?.id as string).pipe(
            catchError(error => of([]))
          ),
          this.licenseService.getCurrentLicense(this.scope, app?.name as string).pipe(
            catchError(error => of({pluginIds: []} as Pick<License, 'pluginIds'>))
          )
        )),
        map(([availablePlugins, license]) => [availablePlugins, license.pluginIds
          .map(pluginId => availablePlugins.find(plugin => plugin.id == pluginId))
          .filter(plugin => plugin)
        ]),
        tap(([_, plugins]) => {
          this.pluginIds = plugins.map(plugin => plugin?.id) as string[],
          this.pluginIds$.next(this.pluginIds)
        }),
        map(([availablePlugins, plugins]) => [
          availablePlugins.map(plugin => plugin?.name),
          plugins.map(plugin => plugin?.name)
        ]),
        reduce(([availablePluginNames1, pluginNames1], [availablePluginNames2, pluginNames2]) => [
          this.mergeWithoutDuplicates(availablePluginNames1 as string[], availablePluginNames2 as string[]),
          this.mergeWithoutDuplicates(pluginNames1 as string[], pluginNames2 as string[])
        ])
      ).subscribe(([availablePluginNames, pluginNames]) => {
        this.availablePluginNames$.next(availablePluginNames as string[])
        this.pluginNames = pluginNames as string[]
        this.pluginNames$.next(this.pluginNames)
      })
    )
  }

  private mergeWithoutDuplicates(array1: Array<string>, array2: Array<string>): Array<string> {
    const mergedArray = array1.concat(array2)
    return mergedArray.filter((item, index) => mergedArray.indexOf(item) === index)
  }

  private getAppsInternal() {
    const customerId = this.scope
    if (customerId == "") {
      return of([])
    } else {
      const customer$ = this.customerService.getCustomer(customerId)
      if (customer$) {
        return customer$
          .pipe(
            mergeMap(customer => customer ? from(customer.appIds as string[]) : from([])),
            mergeMap(appId => (this.appService.getApp(appId) as Observable<App>).pipe(
              catchError(error => of(null))
            )),
            filter(app => app !== null),
            toArray()
          )
      } else {
        return of([])
      }
    }
  }

  getApps(): Observable<App[]> {
    return of(this.apps)
  }

  getAppsObservable(): Observable<App[]> {
    return this.apps$
  }

  getAppNames() {
    return of(this.appNames)
  }

  getAppNamesObservable(): Observable<string[]> {
    return this.appNames$
  }

  getPluginIds(): string[] {
    return this.pluginIds$.getValue()
  }

  getPluginIdsObservable(): Observable<string[]> {
    return this.pluginIds$
  }

  getPluginNames(): Observable<string[]> {
    return of(this.pluginNames)
  }

  getPluginNamesObservable(): Observable<string[]> {
    return this.pluginNames$
  }

  getAvailablePluginNamesObservable(): Observable<string[]> {
    return this.availablePluginNames$
  }

  hasPluginName(pluginName: string): boolean {
    return this.pluginNames.includes(pluginName)
  }

  hasPluginNameObservable(pluginName: string): Observable<boolean> {
    return this.getPluginNamesObservable().pipe(
      map(pluginNames => pluginNames.includes(pluginName))
    )
  }

  getScopeObservable(): Observable<string | undefined> {
    return this.scope$
  }

  getScopeCustomer(): Observable<Customer | undefined> {
    return this.scopeCustomer$
  }

  getCustomerIdOrScope(cid?: string): string {
    let customerId = this.scope
    if (cid) {
      customerId = cid
    }
    return customerId
  }

  getRoleNamesObservable(cid?: string): Observable<string[]> {
    const customerId = this.getCustomerIdOrScope(cid)
    let roles: Observable<string[]>
    if (this.roleNames.has(customerId)) {
      roles = of(this.roleNames.get(customerId) as string[])
    } else {
      roles = this.getRolesOnCustomer(customerId)
    }
    return roles.pipe(
      map(roles => {
        return roles ? roles : []
      })
    )
  }

  getScopeEvents(): Observable<ScopeEvent> {
    return this.events$
  }

  getCustomerRoleIds(customerId: string, roles: UserRoles[]): string[] {
    return roles
      .filter(role => role.customerIds.includes(customerId))
      .map(role => role.roleId)
  }

  private getRecentCustomerIds(): string[] {
    const recentCustomerIds = window.localStorage.getItem(this.RECENT_CUSTOMER_IDS_LOCAL_STORAGE_KEY)
    return recentCustomerIds !== null ? JSON.parse(recentCustomerIds) : []
  }

  getRecentCustomers(): Observable<Customer[]> {
    return from(this.getRecentCustomerIds()).pipe(
      concatMap(customerId => {
        return this.customerService.getCustomer(customerId).pipe(
          catchError(_ => {
            this.removeRecentCustomer(customerId)
            return of()
          })
        )
      }),
      filter(customer => customer !== null),
      toArray()
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  hasRoleName(roleName: string, cid?: string): Observable<boolean> {
    const customerId = this.getCustomerIdOrScope(cid)
    return this.roleNames$.pipe(map(roleNames => roleNames.get(customerId)?.includes(roleName) ?? false))
  }

  hasPermission(permissionValue: string, cid?: string): Observable<boolean> {
    const trie = shiro.newTrie()
    return this.getPermissions(cid).pipe(map(permissionValues =>
      trie.add(...permissionValues).check(permissionValue)
    ))
  }

  hasPermissions(permissionValues: string[], cid?: string): Observable<boolean> {
    const customerId = this.getCustomerIdOrScope(cid)
    let permissions: Observable<string[]>
    const trie = shiro.newTrie()
    if (this.permissions.has(customerId)) {
      permissions = this.getPermissions(customerId)
    } else {
      permissions = this.getPermissionsOnCustomer(customerId)
    }
    return permissions.pipe(map(permissions => {
      trie.add(...permissions)
      return Array.from(permissionValues).every(requiredPermission => trie.check(requiredPermission))
    }))
  }

  hasOneRoleName(roleNames: string[], cid?: string): Observable<boolean> {
    const customerId = this.getCustomerIdOrScope(cid)
    return this.roleNames$.pipe(
      map(userRoleNames => (userRoleNames.get(customerId) ?? []).some(roleName => roleNames.includes(roleName)))
    )
  }

  getPermissions(cid?: string): Observable<string[]> {
    const customerId = this.getCustomerIdOrScope(cid)
    let permissions: Observable<string[]>

    if (this.permissions.has(customerId)) {
      permissions = of(this.permissions.get(customerId) as string[])
    } else {
      permissions = this.getPermissionsOnCustomer(customerId)
    }
    return permissions.pipe(
      map(permissions => {
        return permissions ? permissions : []
      })
    )
  }

  refreshUser() {
    this.subscriptions.add(this.getPermissionsAndRoles(this.scope).subscribe(_ => {
      // nothing to do
    }))
  }

  getPermissionsAndRoles(cid: string): Observable<[string[], string[]]> {
    let permAndRoleSub$ = this.permAndRoles$.get(cid)
    if (!permAndRoleSub$) {
      permAndRoleSub$ = new ReplaySubject<[string[], string[]]>(1)
      this.permAndRoles$.set(cid, permAndRoleSub$)
      const permAndRoles$ = this.userService.getUserWithNestedResources(this.keycloakService.getApiUserId(), cid).pipe(
        mergeMap(user => {
          const permissions = user.permissions.map(p => p.value)
          this.updatePermissions(cid, permissions)

          const roles = user.roles.map(role => role.name)
          this.updateRoles(cid, roles)
          return zip(of(permissions), of(roles))
        })
      )
      permAndRoles$.subscribe(permAndRoleSub$)
    }
    return permAndRoleSub$
  }

  getPermissionsOnCustomer(cid: string): Observable<string[]> {
    return this.getPermissionsAndRoles(cid)
      .pipe(
        map(([permissions, _]) => {
          return permissions
        })
      )
  }

  getRolesOnCustomer(cid: string): Observable<string[]> {
    return this.getPermissionsAndRoles(cid)
      .pipe(
        map(([_, roles]) => {
          return roles
        })
      )
  }

  updateRoles(cid: string, roles: string[]) {
    this.roleNames.set(cid, roles)
    const roleNames = new Map()
    this.roleNames.forEach((names, cid) => {
      roleNames.set(cid, names)
    })
    this.roleNames$.next(roleNames)
  }

  updatePermissions(cid: string, permissions: string[]) {
    this.permissions.set(cid, permissions)
    const perms = new Map()
    for (const cid in this.permissions.keys()) {
      perms.set(cid, this.permissions.get(cid))
    }
    this.permissions$.next(perms)
  }

  getChildren(
    page: Number,
    perPage: Number,
    search?: string
  ): Observable<CustomerNames[]> {
    return this.customerService.getAvailableCustomers(page, perPage, search)
  }
}
